import React from 'react';
import { Col, Row } from 'antd';
import SocLinks from 'components/atom/soc-links';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import { answers } from './helper';

export default function() {
  return (
    <div className="App-Blog">
      <Row gutter={[10, 10]} justify="space-between" align="middle">
        <Col>
          <div className="title-color title-logo">
            <Link to="/">EL MAKLER</Link>
          </div>
        </Col>
        <Col>
          <SocLinks />
        </Col>
      </Row>
      <div className="App-Article App-FAQ-Answers">
        <Row justify="center">
          <Col>
            <h1 className="title-color">FAQ</h1>
          </Col>
        </Row>
        <p>
          Здесь собраны основные вопросы и ответы по проекту. Если вы не нашли
          ответа на свой вопрос -{' '}
          <a
            href="https://t.me/elmakler_support"
            target="_blank"
            rel="noopener noreferrer"
          >
            обратитесь в поддержку
          </a>
          .
        </p>

        <Collapse items={answers} />
      </div>
    </div>
  );
}
