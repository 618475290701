import React from 'react'

export default () => {
  return (
    <div className="App-Button-List">
      <a
        className="App-Tg-Button"
        href="https://t.me/+0WpNL6Z3mk00YzQy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/tg-icon.png" alt="Telegram"/>
      </a>
      <a
        className="App-Inst-Button"
        href="https://www.instagram.com/elmakler"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/inst-icon.png" alt="Instagram"/>
      </a>
    </div>
  )
}
