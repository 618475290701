import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import Main from 'routes/main'
import Manual from 'routes/manual'
import Faq from 'routes/faq'

export default () => {
  return (
    <Switch>
      <Route component={ Main } path="/" exact/>
      <Route component={ Manual } path="/manual" exact/>
      <Route component={ Faq } path="/faq" exact/>
      <Redirect to="/"/>
    </Switch>
  )
};
