import React from 'react'
import { Link } from 'react-router-dom'
import SocLinks from 'components/atom/soc-links'
import { Col, Row } from 'antd'

export default function () {
  return (
    <div className="App">
      <div className="App-Main">
        <Row gutter={ [ 20, 20 ] }>
          <Col span={ 24 }>
            <Row justify="center">
              <Col>
                <div className="stack">
                  <span className="stack-index-0">
                    <div className="App-Title title-color">
                      <div className="App-Title-Main">EL</div>
                      <div className="App-Title-Sub">MAKLER</div>
                    </div>
                  </span>
                  <span className="stack-index-1">
                    <div className="App-Title title-color">
                      <div className="App-Title-Main">EL</div>
                      <div className="App-Title-Sub">MAKLER</div>
                    </div>
                  </span>
                  <span className="stack-index-2">
                    <div className="App-Title title-color">
                      <div className="App-Title-Main">EL</div>
                      <div className="App-Title-Sub">MAKLER</div>
                    </div>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={ 24 }>
            <Row justify="center">
              <Col>
                <div className="App-Desc">
                  El Makler - проект, направленный на анализ и транслирование
                  торговых данных Московской биржи.
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={ 24 }>
            <Row justify="center">
              <Col>
                <div className="title-color">
                  <Link to="/manual">ИНСТРУКЦИЯ</Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={ 24 }>
            <Row justify="center" gutter={ [ 20, 20 ] }>
              <Col>
                <div className="title-color">
                  <Link to="/faq">FAQ</Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={ 24 }>
            <Row justify="center">
              <Col>
                <SocLinks/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}
