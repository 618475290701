import React from 'react'
import { Col, Row } from 'antd'
import SocLinks from 'components/atom/soc-links'
import { Link } from 'react-router-dom'

export default function () {
  return (
    <div className="App-Blog">
      <Row gutter={ [ 10, 10 ] } justify="space-between" align="middle">
        <Col>
          <div className="title-color title-logo">
            <Link to="/">EL MAKLER</Link>
          </div>
        </Col>
        <Col>
          <SocLinks/>
        </Col>
      </Row>
      <div className="App-Article">
        <Row justify="center">
          <Col>
            <h1 className="title-color">ИНСТРУКЦИЯ</h1>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <h2 className="title-color">ОБ АЛГОРИТМЕ</h2>
          </Col>
        </Row>
        <p>
          Идея создать алгоритм появилась летом 2023, когда в нескольких уже существующих ботах мы увидели сильное
          отличие подаваемой информации от реальности, низкую скорость передачи или совершенно неверные данные.
        </p>

        <p>
          Создавая алгоритм, мы выделили две основные задачи:
        </p>

        <h3 className="title-color">СКОРОСТЬ</h3>

        <p>
          Скорость получения информации о проводимых сделках, в настоящий момент, равна примерно 1 ms. Это время, за
          которое алгоритм получает и интерпретирует информацию о сделке после ее проведения на бирже.
        </p>

        <p>
          Далее формируется сообщение, которое поступает в каналы Телеграм, где скорость ограничена самой сетью
          мессенджера. Но общее время не превышает 0,13 секунды!
        </p>

        <h3 className="title-color">ТОЧНОСТЬ</h3>

        <p>
          Для нас было важно, чтобы точность соответствовала в первую очередь нашим личным запросам, и мы могли ответить
          себе на вопрос: «Почему здесь произошло движение?». Используя опыт собственной торговли, у нас получилось
          создать алгоритм, который поможет вовремя подтвердить или опровергнуть верность суждений по активу, распознать
          крупного спекулянта, «кита» или толпу памперов из закрытых чатиков.
        </p>

        <p>
          Мы выяснили, что есть определенные паттерны поведения в стакане и исполнении заявок на ТОП-бумагах российского
          рынка, а так же уникальные модели поведения в бумагах второго и третьего эшелонов.
        </p>
        <Row justify="center">
          <Col>
            <h2 className="title-color mt50">ТИПЫ ОРДЕРОВ</h2>
          </Col>
        </Row>

        <a href="/blogs/manual/screen1.png" target="_blank" rel="noopener noreferrer">
          <img className="App-Article-Img" src="/blogs/manual/screen1.png" alt="Разовый ордер"/>
        </a>

        <p>
          <span className="title-color">Разовый ордер</span> - исполненная заявка по одной конкретной цене с одним
          направлением, т.е. это крупная
          сделка,
          которая выполняется участником торгов в конкретную цену и часто служит началом в исполнении крупного ордера.
          Или начинает набор крупной позиции по цепочке. Для каждой бумаги значения разового ордера разные, что зависит
          от параметров ликвидности.
        </p>

        <p>
          Значения периодически пересматриваются и калибруются, подстраиваясь под ликвидность и режим рынка.
        </p>

        <p>
          Например: в случае со всеми надоевшими UWGN, эти значения трижды пересматривались за время пампа.
        </p>

        <a href="/blogs/manual/screen2.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/screen2.png"
            alt="Накопительный ордер"
          />
        </a>

        <p>
          <span className="title-color">Накопительный ордер</span> - это поток заявок у одного значения цены (или
          узкого диапазона в несколько
          тиков),
          который используется крупным участников для формирования или разгрузки позиции.
        </p>

        <p>
          Поскольку накопительный ордер исполняется в диапазоне цены, то оценивается средневзвешенное значение и
          определяется доля покупок и продаж в этом диапазоне.
        </p>

        <p>
          Доля покупок и продаж является важным индикатором! Мы рекомендуем использовать значения с весом 70%+ в одну из
          сторон, для принятия решений и учитывать контекст формирования заявки.
        </p>

        <p>
          При значениях 50/50 на графике часто можно увидеть мелкие зоны консолидации, которые могут использоваться для
          анализа контекста и последующей оценки ордеров.
        </p>

        <p>
          Накопительный ордер - самая гибкая составляющая алгоритма. Зачастую в ленте встречаются несколько таких
          ордеров, противоположных друг другу, что может говорить как о наличии крупного спекулянта (которому интересны
          небольшие колебания цены), так и о наличии встречного игрока, который формирует противоположную позицию. Имейте
          данный аспект ввиду.
        </p>

        <a href="/blogs/manual/screen3.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/screen3.png"
            alt="Суммирующий ордер"
          />
        </a>

        <p>
          <span className="title-color">Суммирующий ордер</span> - самые крупные суммы будут проявляться именно в
          суммирующем типе ордера. Логика
          такого
          ордера основана на завершении набора/разгрузки позиции крупным игроком.
        </p>

        <p>
          Так же как и в накопительном, здесь присутствует оценка долей исполнений заявок. Как и положено: если кто-то
          покупает, значит кто-то продает. Оценка доли в 70%+ сохраняется.
        </p>

        <p>
          Суммирующий ордер является итогом, но использовать можно в двух вариантах:
        </p>

        <ul>
          <li>
            дополнение к последовательности Разовый + Накопительный + N объем = Суммирующий
          </li>
          <li>
            как самостоятельный объем
          </li>
        </ul>


        <Row justify="center">
          <Col>
            <h3 className="title-color mt50">FULL HOUSE</h3>
          </Col>
        </Row>

        <a href="/blogs/manual/screen4.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/screen4.png"
            alt="FULL HOUSE"
          />
        </a>

        <p>
          Идеальная ситуация, когда все три типа ордеров следуют друг за другом. Возможно не один раз. В таком случае
          движение приобретает наиболее агрессивный характер, а объемы торговли увеличиваются по мере роста цены.
        </p>

        <Row justify="center">
          <Col>
            <h2 className="title-color mt50">ПРИМЕРЫ И ОСОБЕННОСТИ ИСПОЛЬЗОВАНИЯ</h2>
          </Col>
        </Row>

        <p>
          Использовать алгоритм может любой инвестор: будь это day-trader, управляющий фондом, или долгосрочный инвестор,
          который хочет преумножить свои сбережения.
        </p>

        <p>
          Правильное использование алгоритма подразумевает, что у вас есть свои суждения о ценной бумаге, есть цели и
          понятная стратегия действий.
        </p>

        <Row justify="center">
          <Col>
            <h2 className="title-color mt50">СЕТАПЫ</h2>
          </Col>
        </Row>

        <a href="/blogs/manual/YNDX_2024-01-26_22-31-48_11ba9.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/YNDX_2024-01-26_22-31-48_11ba9.png"
            alt="YNDX"
          />
        </a>

        <p>
          Алгоритм хорошо сочетается со стандартными формациями по типу движения в канале, пробоя наклонных линий (в том
          числе на младших тайм-фреймах), отбой или пробой сильных уровней.
        </p>

        <p>
          На графике YNDX видим растянутый по времени и перевёрнутый паттерн «ГиП». Крупные позиции формировались и в
          самом низу, но как пример отработки сильного паттерна подкрепленного объемом выглядит очень показательно.
        </p>

        <a href="/blogs/manual/ASTR_2024-01-26_22-33-56_0aa83.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/ASTR_2024-01-26_22-33-56_0aa83.png"
            alt="ASTR"
          />
        </a>
        <br/>
        <a href="/blogs/manual/screen5.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/screen5.png"
            alt="ASTR"
          />
        </a>

        <p>
          Время удержания позиции зависит от ваших собственных целей. Например, мы формировали позицию по ASTR на пробое
          наклонной линии, что подтверждалось объемом. Частичная фиксация произведена уже после пробоя отметки 550, а
          поскольку покупки продолжаются и нет крупных продаж - большая часть позиции удерживается.
        </p>

        <a href="/blogs/manual/screen6.png" target="_blank" rel="noopener noreferrer">
          <img
            className="App-Article-Img"
            src="/blogs/manual/screen6.png"
            alt="ASTR"
          />
        </a>

        <Row justify="center">
          <Col>
            <h2 className="title-color mt50">НОВОСТИ И ОТЧЕТЫ</h2>
          </Col>
        </Row>

        <p>
          Важные новости по эмитентам всегда вызывают всплеск волатильности, особенно внезапные. Но не всегда этому
          предшествует существенный объем. Чаще всего, при выходе неожиданных новостей, ликвидности в стаканах
          недостаточно, чтобы удовлетворить спрос, и первые крупные объемы проявляются уже по верхней границе диапазона
          (особенности маркетмейкинга на российском рынке). Но иногда, выходу новостей наоборот предшествуют
          подозрительный объем (самой новости еще нет, а объем уже проходит).
        </p>

        <p>
          При публикации отчетов советуем оценить контекст в бумаге заранее. Чаще всего крупные сделки проходят за
          одну-две торговые сессии, а в момент публикации, и первый час после, наблюдается всплеск волатильности и шум в
          объемах.
        </p>


        <Row justify="center">
          <Col>
            <h3 className="title-color mt50">ОТКРЫТИЕ РЫНКА</h3>
          </Col>
        </Row>

        <p>
          Термин dump money придумали не просто так. В первые 15 минут открытия рынка вы увидите всплеск объемов. Это
          нормально. Большинство из них являются закрытием позиций, открытых в предыдущие дни, что так же является
          региональной особенностью.
        </p>


        <Row justify="center">
          <Col>
            <h3 className="title-color mt50">ВЕЧЕРНЯЯ СЕССИЯ</h3>
          </Col>
        </Row>

        <p>
          Но иначе дело обстоит на вечерней торговой сессии. Важно понимать, что далеко не все бумаги из списков
          торгуются после закрытия основной сессии. Часто встречается ситуация, при которой аномальные объемы
          проявляются в последние минуты торговли. Это, скорее всего, приведет к всплеску активности и росту цены уже
          на открытии следующей сессии. Так что при наличии вашего сетапа и аномального объема перед закрытием торгов,
          сделка с переносом через ночь может быть очень успешной.
        </p>

        <p>
          Для бумаг которые торгуются в вечернюю сессию (весь список TOP’s MOEX и большая часть ECHO-2, меньшая часть
          ECHO-3) больше все будут полезны объемы, которые проходят в последние 2 часа торгов. Нередки ситуации, когда
          топы фиксируют позиции в это время, очевидно опасаясь известных им факторов. Наборы новых позиций в это время
          случаются реже, но так же приводят к всплеску волатильности на открытии торгов в следующую сессию.
        </p>


        <Row justify="center">
          <Col>
            <h3 className="title-color mt50">ПАМПЫ</h3>
          </Col>
        </Row>

        <p>
          В 2023 году практически не было бумаги в третьем эшелоне, которую не разогнал тот или иной телеграмм-канал. У
          пампов есть одна неприятная особенность - искажение логики. Когда толпа ломится в одну бумагу, конечно же
          используя при этом плечи, стандартные параметры волатильности у такого эмитента быстро нарушаются.
        </p>

        <p>
          Последний пример: APTK, EUTR - за несколько часов бумаги выходили в ТОП-5 по обороту торгов, нарушив
          стандартные отклонения в волатильности. Это приводит в большому количеству информации в ленте бота, но
          информация корректируется на следующий или в этот же день, если удается быстро определить параметры
          отклонения.
        </p>

        <p>
          Другой пример: UWGN, FIXP - на фоне отсутствия роста рынка по ширине, в период ноября-декабря 2023 (по сути
          отсутствие роста индекса), у толпы не было интереса и других идей, кроме подобных пампов. Но сильное движение
          быстро заканчивалось, а желания открыть позиции в надежде на быстрый рост - нет. Это приводило к увеличению
          торговых объемов без существенного изменения цены на несколько недель. Например, UWGN несколько раз опережали
          по обороту SBER, что приводило к повышению допустимых границ для алгоритма (условно, чтобы сдвинуть «вагоны»
          требовался такой же объем, как для Сбербанка, а это десятки миллионов по разовому ордеру).
        </p>

        <Row justify="center">
          <Col>
            <h3 className="title-color mt50"><i>ДИСКЛЕЙМЕР</i></h3>
          </Col>
        </Row>

        <p>
          <i>
            Вся предоставляемая информация не является инвестиционной рекомендацией. Участники торгов самостоятельно
            принимают решения о проводимых сделках. Финансовые инструменты, либо операции, упомянутые в ней, могут не
            соответствовать инвестиционному профилю и инвестиционным целям (ожиданиям).
          </i>
        </p>
      </div>
    </div>
  )
}
