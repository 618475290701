import React from 'react';

export const answers = [
  {
    key: '1',
    label: 'Как зайти в бота?',
    children: (
      <div>
        <p>
          Ссылка на бота доступна в каждом сообщении, в закрепленных сообщениях
          и в шапке профиля канала.
        </p>
        <p>
          <i>
            Бот работает через Телеграм. Ничего подключать и устанавливать не
            требуется. Вы просто получаете сообщения из каналов.
          </i>
        </p>
        <a
          href="https://t.me/elMakler_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Перейти в бота
        </a>
      </div>
    ),
  },
  {
    key: '2',
    label: 'Пробный период еще доступен?',
    children: (
      <div>
        <p>
          Да, промокод <b>FREETRIAL</b> работает для всех новых подписчиков.
        </p>
      </div>
    ),
  },
  {
    key: '3',
    label: 'Как вообще использовать бота, как он работает и почему?',
    children: (
      <div>
        <p>
          В боте доступна инструкция. Чтобы ее открыть не требуется подписка.
          Достаточно перейти по ссылке{' '}
          <a
            href="https://elmakler.com/manual"
            target="_blank"
            rel="noopener noreferrer"
          >
            Инструкция
          </a>
          .
        </p>
        <p>
          В инструкции изложены принципы и объяснения, какие типы ордеров для
          чего используются.
        </p>

        <p>
          Если обобщить, то алгоритмы бота могут использоваться:
          <ul>
            <li>для активного трейдинга</li>
            <li>для формирования среднесрочных позиций</li>
            <li>
              для анализа контекста и формирования долгосрочных позиций при
              должном навыке
            </li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    key: '4',
    label: 'В ECHO-3 много информации. Иногда не успеваешь читать.',
    children: (
      <div>
        <p>
          <i>
            Алгоритм учитывает определенные параметры волатильности. Последние
            три месяца рынок был не в лучшем режиме. Ликвидность сильно упала,
            что наложилось на период теста командой тестирования. На текущий
            момент ширина рынка восстанавливается.
          </i>
        </p>
        <p>
          Со временем алгоритм учтет волатильность и значения для большинства
          неликвидных бумаг скорректируются.
        </p>
      </div>
    ),
  },
  {
    key: '5',
    label: 'Будут ли изменения и дополнения в функционале?',
    children: (
      <div>
        <p>
          Да, на данный момент включили в разработку следующие функции:
          <ul>
            <li>
              оповещения сочетания ордеров <b>#FullHouse</b>, которая является
              сильной комбинацией и может использоваться как торговый паттерн
            </li>
            <li>
              оповещения аномального изменения цены без объема (снова будет
              выгодно для ECHO-3)
            </li>
            <li>
              в текст сообщения будут добавлены значения внутридневного объема
              по эмитенту
            </li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    key: '6',
    label: 'Что за графики с кружочками объемов? Где такое посмотреть?',
    children: (
      <div>
        <a
          href="/blogs/faq/chart-example.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="App-Article-Img"
            src="/blogs/faq/chart-example.png"
            alt="Пример графика"
          />
        </a>
        <p>
          <i>
            Этот функционал так же пока в разработке и будет добавлен вместе с
            новым типом подписки позднее.
          </i>
        </p>
      </div>
    ),
  },
];
