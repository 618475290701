import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter, RouterState } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

export const history = createBrowserHistory({basename: '/'})

const enhancers = []

const middleware = [ thunk, routerMiddleware(history) ]

const reducer = combineReducers({
  router: connectRouter(history)
})

export interface Store {
  router: RouterState;
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REDUX_LOGGER) {
  const devToolsExtension = window['__REDUX_DEVTOOLS_EXTENSION__' as string]
  const logger = createLogger()

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  middleware.push(logger)
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export const store = createStore(reducer, {}, composedEnhancers)
